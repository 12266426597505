<template>
  <a-spin :spinning="pageLoading">
    <a-breadcrumb separator="<">
      <a-breadcrumb-item>
        <router-link :to="{path:routerHeaderInfo.router,query:routerHeaderInfo.query}">
          {{routerHeaderInfo.beforeRouterName}}
        </router-link>

      </a-breadcrumb-item>
      <a-breadcrumb-item>{{routerHeaderInfo.nowName}}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-divider class="my-8 " />

    <a-tabs v-model:activeKey="tabActive"
            type="card">
      <a-tab-pane key="corp">
        <template #tab>
          <span class="text-color-666">
            绑定销客助手（<span :class="{'text-danger': !accountStatus.isCorpConfiged}">{{ accountStatus.isCorpConfiged ? '已绑定' : '未绑定' }}</span>）
          </span>
        </template>
        <corp-account />
      </a-tab-pane>
      <a-tab-pane key="wechat">
        <template #tab>
          <span class="text-color-666">
            绑定企业微信（<span :class="{'text-danger': !accountStatus.isWechatConfiged}">{{ accountStatus.isWechatConfiged ? '已绑定' : '未绑定' }}</span>）
          </span>
        </template>
        <wechat-account :status="accountStatus.isWechatConfiged" />
      </a-tab-pane>
    </a-tabs>
  </a-spin>

</template>

<script>
import { defineComponent, ref, reactive } from 'vue';
import { Tabs, Breadcrumb } from 'ant-design-vue';

import CorpAccount from '@/views/systemSetting/officialAccount/OfficialAccountCorp';
import WechatAccount from '@/views/systemSetting/officialAccount/OfficialAccountWechat';

import corpSettingApi from '@/service/api/corpSetting';

export default defineComponent({
  name: 'OfficialAccountIndex',

  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ABreadcrumb: Breadcrumb,
    ABreadcrumbItem: Breadcrumb.Item,

    CorpAccount,
    WechatAccount
  },
  setup() {
    const routerHeaderInfo = {
      router: 'systemSetting',
      beforeRouterName: '系统设置',
      nowName: '公众号'
    };

    const tabActive = ref('corp');
    const pageLoading = ref(true);

    const accountStatus = reactive({});
    const getAccountStatus = async () => {
      const res = await corpSettingApi.getConfigurations();

      accountStatus.isCorpConfiged =
        res.setWechatConf.wechatPlatformStatus === 'open';
      accountStatus.isWechatConfiged =
        res.setWechatConf.bindWechatAccountStatus === 'open';

      pageLoading.value = false;
    };

    getAccountStatus();

    return {
      routerHeaderInfo,
      tabActive,
      pageLoading,

      accountStatus
    };
  }
});
</script>
<style lang='less' scoped>
</style>